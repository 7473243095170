import React, { useEffect } from "react"
import { graphql } from "gatsby"
// Components
import Layout from "components/Layout"
import SEO from "components/SEO"
import { NormalPadding } from "styles/GlobalStyles"

import handleAlerts from '../functions/handleAlerts'

const Page = ({ data, location }) => {
  console.log('[homepage] location #fdvsd: ', location)
  useEffect(() => {handleAlerts(location)}, []);

  var page = data.nodePage
  console.log('in home.js (#4jf034fnd). page = ', page, ' location: ', location)

  return (
      <Layout>
          <NormalPadding>
              <SEO title={page.title} />
              
              <main>
                  <h2>{page.title}</h2>
                  <div dangerouslySetInnerHTML={{ __html: page.body.processed }} />
              </main>
          </NormalPadding>
      </Layout>
    )
}

export default Page

export const query = graphql`
query FindHomePage ($nid: Int) {
    nodePage(drupal_internal__nid: {eq: $nid}) {
      id
      title
      fields {
        slug
      }
      body {
        processed
      }
    }
}  
`